import IconFull from '../full';
import IconMeliCoins from '../meli-coins';
import IconHeartEmpty from '../heart-empty';
import IconHeartFull from '../heart-full';
import IconChevron from '../chevron';
import IconBufloInfo from '../buflo-info';

export const IconRecommendationsFull = {
  ...IconFull,
  ICON_ID: 'recommendations-full_icon',
};
export const IconRecommendationsMeliCoins = {
  ...IconMeliCoins,
  ICON_ID: 'recommendations-meli_coins',
};
export const IconRecommendationsHeartEmpty = {
  ...IconHeartEmpty,
  ICON_ID: 'recommendations-heart-empty',
};
export const IconRecommendationsHeartFull = {
  ...IconHeartFull,
  ICON_ID: 'recommendations-heart-full',
};
export const IconRecommendationsChevron = {
  ...IconChevron,
  ICON_ID: 'recommendations-chevron',
};
export const IconRecommendationsBufloInfo = {
  ...IconBufloInfo,
  ICON_ID: 'recommendations-buflo_icon_info',
};

// @TODO: Falta agregar icon p/ CPG y Recomendado
// Cuando se agreguen los casos en el middleend
// No se traen desde Recommendations-fe para no subir peso
